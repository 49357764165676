import React, { useState, useEffect } from 'react' // eslint-disable-line no-use-before-define
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'

const Index = () => {
  const [landingPage, setLandingPage] = useState()

  useEffect(() => {
    const run = async () => {
      // HACK: Dynamically import landing page depending on device type
      const module = isMobile
        ? await import('../components/Landing/LandingMobile')
        : await import('../components/Landing/LandingDesktop')
      // This step is needed to prevent React rerender error.
      // Not quite sure why
      const LandingPage = module.default
      setLandingPage(<LandingPage />)
    }
    run()
  }, [])
  return (
    <>
      {/* Page title */}
      <Helmet>
        <title>NFT Gallery</title>
        <link rel="icon" type="image/png" href="https://cdn.hibervr.com/static/icons/app/nobg/head-512.png" />
      </Helmet>

      { landingPage }
    </>
  )
}

export default Index
